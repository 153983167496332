import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Nav, Navbar, Dropdown, Button, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import numberWithCommas from "../utils/numbersWithComma";
import { getNotifications } from "../redux/app/actions";

import MenuIcon from "../assets/svg/MenuIcon";
import WhatsappIcon from "../assets/images/icons/whatsapp.png";
import logo from "../assets/images/logo.webp";
import UserIcon from "../assets/images/icons/user.png";
import NotificationIcon from "../assets/images/icons/bell.png";

const HeaderAfterLogin = () => {
  const { user, wallet, exposureAmount, balance } = useSelector(
    (state) => state.auth
  );

  const { betMatches, userBets } = useSelector((state) => state.sports);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showexchangeModal, setShowexchangeModal] = useState(false);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);
  const [showToggle, setShowToggle] = useState(true);
  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);
  useEffect(() => {
    setShowToggle(window.screen.width > 992);
    loadNotifications();
    return () => {};
  }, []);

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  const handleRowClick = (item) => {
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  useEffect(() => {
    if (showLeftbarToggle) {
      document.body.classList.add("leftbat-opened");
    } else {
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftbarToggle]);

  const [isSubMenuActive, setIsSubMenuActive] = useState(false);
  const handleMenuClick = () => {
    setIsSubMenuActive(!isSubMenuActive);
  };
  const inlineStyle = isSubMenuActive ? { overflowX: "clip" } : {};

  const location = useLocation();

  const [activePage, setActivePage] = useState("");
  const footerPages = [
    "/betby",
    "/sports/Inplay",
    "/sports/Cricket",
    "home",
    "/livecasino",
    "/gatewaylist",
    "/",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <header className="header aftrlgn">
      <div className="top_head">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="logo">
                    <a href="/home">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                      {/* <img src={logo} alt="Logo" /> */}
                    </a>
                  </div>
                </div>

                <Navbar expand="xl" className="d-none d-xl-block">
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    onClick={() => setShowToggle((s) => !s)}
                  />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                      <Nav.Link
                        className={
                          activePage === "/sports/Inplay" ? "active" : ""
                        }
                        href="/sports/Inplay"
                      >
                        Inplay
                      </Nav.Link>
                      <Nav.Link
                        className={
                          activePage === "/sports/Cricket" ? "active" : ""
                        }
                        href="/sports/Cricket"
                      >
                        Exchange
                      </Nav.Link>
                      <Nav.Link
                        className={activePage === "/betby" ? "active" : ""}
                        href="/betby"
                      >
                        Sportsbook
                      </Nav.Link>
                      <Nav.Link
                        className={activePage === "/livecasino" ? "active" : ""}
                        href="/livecasino"
                      >
                        Live Casino
                      </Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>

                <div className="headerRight">
                  <ul className="social">
                    <li>
                      <a
                        className="panel"
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                            : "javascript:void(0)"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        <img src={WhatsappIcon} alt="whats app" />
                      </a>
                    </li>
                  </ul>
                  <ul className="HeaderMyBets d-md-block d-none">
                    <li>
                      <a href="/mybets" className="btn btn-primary">
                        My Bets
                      </a>
                    </li>
                  </ul>
                  <ul className="BalanceSection">
                    <li>
                      Bal: <span>{wallet?.balance}</span>
                    </li>
                    <li>
                      Bonus: <span>{numberWithCommas(wallet?.coins)}</span>
                    </li>
                    <li>
                      Cash: <span>{numberWithCommas(wallet?.cash)}</span>
                    </li>
                  </ul>
                  <Dropdown
                    align="end"
                    className=" rightbarDropdown d-md-block d-none"
                  >
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <img src={UserIcon} alt="UserIcon" />
                      {user?.mstruserid}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="javascript:void(0)">
                        {user?.mstruserid}
                        <br />
                        <span>{numberWithCommas(wallet?.cash || 0)}</span>
                      </Dropdown.Item>
                      <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                      <Dropdown.Item href="/mybets">My Bets</Dropdown.Item>
                      <Dropdown.Item href="/cashier">
                        Account Statement
                      </Dropdown.Item>
                      <Dropdown.Item href="/referral">Referral</Dropdown.Item>
                      <Dropdown.Item href="/gatewaylist">Deposit</Dropdown.Item>
                      <Dropdown.Item href="/withdraw">Withdraw</Dropdown.Item>
                      <Dropdown.Item
                        href="/"
                        onClick={() => {
                          dispatch(logoutUser());
                        }}
                      >
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="d-block d-md-none">
                    <ul className="MobileProfile">
                      <li>
                        <a href="/dashboard">
                          <img src={UserIcon} alt="UserIcon" />
                          {user?.mstruserid}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <Button variant="primary menu-btn" onClick={handleShow}>
                    {/* <MenuIcon /> */}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom_head">
        <Container fluid>
          <Row>
            <Col xs={12} className="px-0">
              <ul style={inlineStyle}>
                <li className="allGames">
                  <a href="/livecasino" onClick={handleMenuClick}>
                    <span>Live Casino</span>
                  </a>
                </li>
                <li>
                  <a href="/sports/Inplay">
                    <span>Exchange</span>
                  </a>
                </li>
                <li className="allGames">
                  <a href="/betby" onClick={handleMenuClick}>
                    <span>Sportsbook</span>
                  </a>
                </li>
                <li>
                  <a href="/sports/Cricket">
                    <span>Cricket</span>
                  </a>
                </li>
                <li>
                  <a href="/sports/Soccer">
                    <span>Football</span>
                  </a>
                </li>
                <li>
                  <a href="/sports/Tennis">
                    <span>Tennis</span>
                  </a>
                </li>
                <li>
                  <a href="/sports/Horse Racing">
                    <span>Horse Racing</span>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        className="betslip_popup outer_popup"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showBetModal}
      >
        <Modal.Body>
          {" "}
          <main className="main">
            <div className="container table-responsive">
              <table className="table text-white">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Exposure amount</th>
                    <th scope="col">Match name</th>
                    <th scope="col">Ref match ID</th>
                    <th scope="col">Total bets</th>
                  </tr>
                </thead>
                <tbody>
                  {betMatches &&
                    betMatches.length &&
                    betMatches.map((item, index) => {
                      return (
                        <tr
                          onClick={() => {
                            handleRowClick(item);
                          }}
                        >
                          <th scope="row">{index + 1}</th>
                          <td>{item?.exposureAmount}</td>
                          <td>{item?.matchName}</td>
                          <td>{item?.refMatchId}</td>
                          <td>{item?.totalBets}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </main>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowBetModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="betslip_popup inner_popup"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showUserBetModal}
      >
        <Modal.Body>
          {" "}
          <main className="main">
            <div className="container table-responsive">
              <table className="table text-white">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Odds</th>
                    <th scope="col">Stack</th>
                    <th scope="col">Selection type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Create date</th>
                  </tr>
                </thead>
                <tbody>
                  {userBets &&
                    userBets.length &&
                    userBets.map((item, index) => {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{item?.odds}</td>
                          <td>{item?.stack}</td>
                          <td>{item?.selectionType}</td>
                          <td>{item?.status}</td>
                          <td>{item?.createdAt}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </main>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      {(websiteNotifications.length && showNotifications && (
        <div className="Announcement">
          <div className="latest">
            <img src={NotificationIcon} alt="NotificationIcon" />
            Latest
          </div>
          <marquee>
            {" "}
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }}>{notf?.content}</label>
            ))}
          </marquee>
        </div>
      )) ||
        null}
    </header>
  );
};

export default HeaderAfterLogin;
